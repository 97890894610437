
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        












































































































































.about__main__numbers {
  [class*='action'] {
    display: block;
    margin-right: auto;
    margin-left: auto;
  }

  @include mq(s) {
    padding-right: col(1, 16);
    padding-left: col(1, 16);
  }
}

.about__faq {
  ::v-deep {
    h2 {
      @extend %fw-normal;

      margin-bottom: calc(1em - 0.8rem);
    }
  }

  @include mq(l) {
    ::v-deep {
      .g-lead {
        font-size: 2rem;
      }
    }
  }
}

.about__faces {
  @extend %text-center;

  .g-lead {
    max-width: 25em;
    margin-right: auto;
    margin-left: auto;
    color: rgba($c-black, 0.6);
  }
}

.about__faces__overlayer {
  ::v-deep {
    .g-overlayer__inner {
      padding-right: 0;
      padding-bottom: 0;
      padding-left: 0;
    }

    .g-overlayer__button {
      margin-right: col(1, 20);

      @include mq(l) {
        margin-right: col(0.5, 19);
      }
    }
  }
}
