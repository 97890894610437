
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        



















































.page {
  display: flex;
  flex-direction: column;

  > div:first-child {
    flex: 1;
  }
}
