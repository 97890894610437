
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        











































































































































































































































.affiliation-keyfacts,
[class*='affiliation-keyfacts--'] {
  position: relative;
}

.affiliation-keyfacts__picture-outer {
  position: sticky;
  top: 0;
}

.affiliation-keyfacts__picture {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  background: $c-black-true;
  border-radius: 2rem;
  transition: all 0.5s;

  img {
    @include image-fit;

    transform: scale(1.1);
    transition: opacity 0.5s;

    .about &,
    .is-active & {
      opacity: 0.6;
    }
  }
}

.affiliation-keyfacts__content {
  position: sticky;
  top: 0;
  margin-top: -100vh;

  @include mq($until: wrapper) {
    padding-right: col(2, 20);
    padding-left: col(2, 20);
  }
}

.affiliation-keyfacts__header {
  @extend %text-center;

  color: $c-white;

  .h3 {
    @extend %fw-normal;

    max-width: 10em;
    margin: auto;
  }

  p {
    max-width: 28em;
    margin: auto;
  }
}

.affiliation-keyfacts__list {
  @extend %list-nostyle;

  counter-set: keyfacts;
}

.affiliation-keyfacts__item {
  max-width: 40rem;
  padding: $spacing;
  color: $c-white;
  background: rgba($c-black-true, 0.5);
  backdrop-filter: blur(8px);
  border-radius: 1.1rem;
  counter-increment: keyfacts;
  transition: 0.5s ease-out;

  &.is-hidden {
    opacity: 0;
    transform: translateY(100px);
  }

  & + & {
    @include fluid(
      margin-top,
      (
        xxs: 70px,
        xl: 160px,
      )
    );
  }

  &:nth-child(2n + 1) {
    margin-left: auto;
  }

  .about & {
    align-items: center;
    width: fit-content;
    padding: $spacing * 0.5 $spacing;
    font-size: 2rem;
    font-weight: 600;
    border-radius: 8rem;

    // Liège + Namur
    &:nth-child(1),
    &:nth-child(3) {
      margin-left: auto;
    }

    & + & {
      @include fluid(
        margin-top,
        (
          xxs: 70px,
          xl: 120px,
        )
      );
    }
  }

  @include mq(l) {
    padding: $spacing * 1.5;

    &:nth-child(1) {
      margin-left: col(5, 16);
    }

    &:nth-child(2) {
      margin-right: col(1, 16);
      margin-left: auto;
    }

    &:nth-child(3) {
      margin-left: col(2, 16);
    }

    &:nth-child(4) {
      margin-right: col(3, 16);
      margin-left: auto;
    }

    &:nth-child(5) {
      margin-left: col(2, 16);
    }

    &:nth-child(6) {
      margin-left: auto;
    }

    .about & {
      max-width: none;
      margin-top: $spacing * 2.6;
      padding: $spacing * 0.9 $spacing * 1.4 $spacing * 0.9 $spacing;
      font-size: 3.4rem;

      // Liège
      &:nth-child(1) {
        margin-right: col(4, 16);
      }

      // Liège Grand Poste
      &:nth-child(2) {
        margin-left: col(2, 16);
      }

      // Verviers
      &:nth-child(3) {
        margin-right: col(2, 16);
      }

      // Namur
      &:nth-child(4) {
        margin-left: col(3, 16);
      }
    }
  }
}

.affiliation-keyfacts__item-inner {
  @extend %button-nostyle;

  display: flex;
  align-items: flex-start;
  color: inherit;

  .about & {
    align-items: center;
    white-space: nowrap;
  }
}

.affiliation-keyfacts__item__picture {
  position: relative;
  overflow: hidden;
  width: 5.3rem;
  height: 5.3rem;
  margin-right: $spacing * 1.2;
  border-radius: 50%;

  img {
    @include image-fit;
  }
}

.affiliation-keyfacts__item__icon {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 3.5rem;
  height: 3.5rem;
  margin-right: $spacing * 1.2;
  color: $c-blue-80;
  background: $c-blue-10;
  border-radius: 50%;

  @include mq(l) {
    .about & {
      width: 5.3rem;
      height: 5.3rem;

      &::before {
        font-size: 3.4rem;
      }
    }
  }
}

.affiliation-keyfacts__item__arrow {
  margin-left: $spacing;
  transition: transform 0.2s;

  .affiliation-keyfacts__item:hover &,
  .affiliation-keyfacts__item:focus-visible & {
    transform: translateX(0.5rem);
  }
}

.affiliation-keyfacts__intro,
.affiliation-keyfacts__cta {
  color: $c-white;

  p {
    @extend %fw-normal;

    max-width: 30em;
  }
}
