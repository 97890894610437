
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        






























.partner-hero-inner {
  @extend %text-center;

  position: relative;
  overflow: hidden;
  color: $c-white;
  background: $c-blue-80;
  border-radius: 4rem;
}

.partner-hero__picture {
  @include get-all-space;

  &::before {
    @include get-all-space;

    content: '';
    z-index: 1;
    background: rgba($c-black, 0.4);
  }
}

.partner-hero__content {
  position: relative;
  z-index: 1;
  padding: $spacing * 7 0 $spacing * 6.5;

  @include mq(m) {
    padding: $spacing * 10 0 $spacing * 7;
  }

  @include mq(wrapper) {
    padding: $spacing * 12.5 0 $spacing * 10;
  }
}

.partner-hero__title {
  color: $c-white;
}

.partner-hero__text {
  padding: 0 col(2, 18);

  @include mq(l) {
    padding: 0 col(4, 18);
  }

  @include mq(wrapper) {
    padding: 0 col(4, 16);
  }
}
