
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        














































::v-deep {
  .g-faq .g-lead {
    @include mq(wrapper) {
      padding: 0 col(1, 12);
    }
  }
}
