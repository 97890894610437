
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        





































































.affiliation-rates-outer {
  @include mq($until: m) {
    margin-top: $spacing * 3;
  }

  @include mq(m) {
    position: relative;
    z-index: 2;
    margin-bottom: 0;
    padding-right: col(2, 20);
    padding-left: col(2, 20);
    transform: translateY(50%);
  }
}

.affiliation-rates,
[class*='affiliation-rates--'] {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: $spacing * 1.8 col(2, 18) $spacing * 2.4;
  background: $c-white;
  border-radius: 3rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);

  @include mq(m) {
    flex-direction: row;
    justify-content: space-between;
    padding: $spacing * 1.8 col(1, 16) $spacing * 1.4;
  }

  @include mq(xl) {
    margin: 0 col(1, 16);
  }
}

.affiliation-rates__title {
  color: $c-black-80;
}

.affiliation-rates__select.form-select-outer {
  width: 28rem;
  margin: $spacing * 1.2 0 $spacing * 1.6;
  background: $c-white;

  ::v-deep .form-select__select {
    @extend %fw-medium;

    color: $c-black;
    font-size: 2rem;
  }

  @include mq(m) {
    flex: 28rem 0 0;
    margin: 0 col(0.5, 16);
  }

  @include mq(l) {
    margin: 0 col(1, 16) 0 col(0.5, 16);
  }
}

.affiliation-rates__price {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: $spacing * 1.6;

  @include mq(m) {
    flex-direction: row;
    margin: 0 col(1, 16) 0 0;
  }
}

.affiliation-rates__price__number {
  @extend %fw-medium;

  display: flex;
  align-items: center;
  font-size: 6.8rem;
  line-height: 1;

  @include mq($from: m, $until: xl) {
    font-size: 4rem;
  }
}

.affiliation-rates__price__number__euro {
  margin-left: $spacing * 0.25;

  .htva {
    margin-top: -$spacing * 0.5;
    font-size: 1.6rem;
  }
}
