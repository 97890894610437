
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        






































































































.affiliation-form__title,
.affiliation-form__intro {
  @extend %text-center;
}

.affiliation-form__intro {
  max-width: 20em;
  margin-right: auto;
  margin-left: auto;
  color: $c-black-80;
}
