
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        










































































.g-related {
  padding-top: 0;
  background: transparent;
}

.g-baseline {
  @include mq($until: l) {
    padding: 0 col(2, 20);
  }
}

.affiliation-popup {
  ::v-deep .g-popup {
    align-self: flex-start;
    width: 100%;
    padding: $spacing * 2 col(1, 18);
    background: $c-white;
    border-radius: 3rem;
  }

  @include mq(xl) {
    ::v-deep .g-popup {
      width: col(14, 18);
      padding: $spacing * 3 col(1, 18);
    }
  }

  @include mq(wrapper) {
    ::v-deep .g-popup {
      width: col(14, 20);
      padding: $spacing * 3 col(1, 20);
    }
  }
}
