
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        






































































::v-deep .g-content__main {
  @include mq(wrapper) {
    padding-top: 16.5rem;
  }
}

.about-partners__pictures {
  position: relative;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  width: 100%;
  height: 30vw;
}

.about-partners__picture {
  position: absolute;
  top: 0;
  overflow: hidden;
  height: 100%;
  border-radius: 1.6rem;

  img {
    @include image-fit;
  }

  &:nth-child(1) {
    left: col(-1, 20);
    width: col(8, 20);
  }

  &:nth-child(2) {
    left: col(8, 20);
    width: col(5, 20);
  }

  &:nth-child(3) {
    left: col(14, 20);
    width: col(12, 20);
  }
}

.about-partners__title,
.about-partners__subtitle {
  @extend %text-center;
}

.about-partners__subtitle {
  color: $c-black-80;
  font-size: 2rem;
}

.about-partners__logos {
  display: flex;
  overflow: hidden;
  padding: 0;
  list-style: none;
}

.about-partners__logos__list-outer {
  display: inline-block;
  will-change: transform;
  animation: go-left linear 30s infinite;
}

.about-partners__logos__list {
  display: flex;
  height: 15rem;
  padding: 0;
  list-style-type: none;
  white-space: nowrap;
}

.about-partners__logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25rem;
  height: 100%;
  margin-left: 5rem;
  padding: 3.6rem;
  background: $c-white;
  border: 2px solid $c-black-40;
  border-radius: 1rem;
}

.about-partners__content {
  @extend %text-center;

  .g-light {
    margin-right: auto;
    margin-left: auto;
    border-top: 1px solid $c-black-40;
  }

  img {
    width: 100%;
    max-width: 24rem;
    margin: $spacing * 0.5 auto 0;
    mix-blend-mode: multiply;
  }
}

@keyframes go-left {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-100%);
  }
}
